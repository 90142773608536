// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$primaryBlue: #336699;
$blue: #4a79ba;
$lightBlue: #6c95cc;
$lightestBlue: #89acda;
$lightGrey: #eaeef1;
$red: #f44336;
$ww-red: #dc3545;
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$gray-primary-text: #9e9e9d;

$chepp-primary: (
    50: $lightestBlue,
    100: $lightBlue,
    200: $blue,
    300: $primaryBlue,
    400: $primaryBlue,
    500: $primaryBlue,
    600: $primaryBlue,
    700: $primaryBlue,
    800: $primaryBlue,
    900: $primaryBlue,
    A100: $primaryBlue,
    A200: $primaryBlue,
    A400: $primaryBlue,
    A700: $primaryBlue,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $light-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $light-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$chepp-red: (
    50: $red,
    100: $red,
    200: $red,
    300: $red,
    400: $red,
    500: $red,
    600: $red,
    700: $red,
    800: $red,
    900: $red,
    A100: $red,
    A200: $red,
    A400: $red,
    A700: $red,
    contrast: (
        50: $light-primary-text,
        100: $light-primary-text,
        200: $light-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $light-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$chepp-accent: (
    50: $lightGrey,
    100: $lightGrey,
    200: $lightGrey,
    300: $lightGrey,
    400: $lightGrey,
    500: $lightGrey,
    600: $lightGrey,
    700: $lightGrey,
    800: $lightGrey,
    900: $lightGrey,
    A100: $lightGrey,
    A200: $lightGrey,
    A400: $lightGrey,
    A700: $lightGrey,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $dark-primary-text,
        700: $dark-primary-text,
        800: $dark-primary-text,
        900: $dark-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$CheppPortaal-primary: mat-palette($chepp-primary);
// $CheppPortaal-accent: mat-palette($mat-pink, A200, A100, A400);
$CheppPortaal-accent: mat-palette($chepp-accent);

// The warn palette is optional (defaults to red).
$CheppPortaal-red: mat-palette($chepp-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$CheppPortaal-theme: mat-light-theme(
    (
        color: (
            primary: $CheppPortaal-primary,
            accent: $CheppPortaal-accent,
            warn: $CheppPortaal-red,
        ),
    )
);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
@include angular-material-theme($CheppPortaal-theme);

// /* You can add global styles to this file, and also import other style files */

$primary: mat-color($CheppPortaal-primary);
$blue: mat-color($CheppPortaal-primary, 200);
$lightBlue: mat-color($CheppPortaal-primary, 100);
$lightestBlue: mat-color($CheppPortaal-primary, 50);
$ticketBlue: #afccf2;
$accent: mat-color($CheppPortaal-accent);
$red: mat-color($CheppPortaal-red);
$white: #ffffff;
$black: #000000;
$green: #4caf50;
$orange: #ff9800;
$standard: #B4C4D3;

mat-form-field.mat-form-field {
    font-size: 16px;
}
