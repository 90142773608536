@import "./chepp-theme.scss";

html,
body {
    height: 100%;
    background-color: $lightGrey;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.snackbar-custom {
    border-radius: 1vh !important;
    margin: 2.5vh !important;
    min-width: 0 !important;
    padding: 2vh 1.5vw !important;
    min-height: 0 !important;
    color: white !important;
    user-select: none;
}

.snackbar-error {
    border-radius: 1vh !important;
    margin: 2.5vh !important;
    min-width: 0 !important;
    padding: 2vh 1.5vw !important;
    min-height: 0 !important;
    background-color: $red !important;
    color: white !important;
    user-select: none;
}

.snackbar-succes {
    border-radius: 1vh !important;
    margin: 2.5vh !important;
    min-width: 0 !important;
    padding: 2vh 1.5vw !important;
    min-height: 0 !important;
    background-color: $green !important;
    color: white !important;
    user-select: none;
}

.hercontrole-dialog {
    mat-dialog-container {
        margin: 0 !important;
        padding: 0 !important;
        overflow-y: hidden;
        border-radius: 7px;
    }
}

.vraag-hercontrole-dialog {
    mat-dialog-container {
        margin: 0 !important;
        padding: 0 !important;
        overflow-y: hidden;
        border-radius: 7px;
    }
}

.fullscreen-dialog {
    mat-dialog-container {
        box-shadow: none !important;
        padding: 0 !important;
    }

    border: 0 !important;
    max-width: unset !important;
    width: 100%;
    height: 100%;
    box-shadow: 0;

    .mat-dialog-container {
        max-width: 100vw;
        max-height: 100vh;
        height: 100%;
        width: 100%;
        background-color: rgba($color: black, $alpha: 0);

        .mat-dialog-content {
            max-height: unset !important;
        }
    }
}

.waardeoordeel-popup {
    mat-dialog-container {
        padding: 0 !important;
        width: 250px !important;
        border-radius: 12px !important;
        border: 1px solid rgb(159, 159, 159) !important;
    }
}

.cdk-overlay-dark-backdrop {
    background-color: rgba($color: $primaryBlue, $alpha: 0.7) !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #4caf50;
}

span.mat-slide-toggle-thumb {
    transform: scale(1); // 0.6
}

.mat-expansion-panel {
    box-shadow: none !important;
}

.mat-expansion-panel-header {
    padding: 0 16px !important;
    align-content: center !important;
}

.mat-expansion-panel-body {
    padding: 0px 0px !important;
}

.mat-button-toggle-group {
    border: none !important;
}

.mat-button-toggle {
    border: none !important;
}

.mat-card-title {
    font-size: 18px !important;
}

.mat-expansion-panel {
    box-shadow: none !important;
}

.mat-expansion-panel-header {
    padding: 0 16px !important;
}

.mat-expansion-panel-body {
    padding: 0px 0px !important;
}

.checkItemCard .mat-card-subtitle {
    color: white;
}

.deelplanItemCard .mat-expansion-panel-header-title {
    color: $primaryBlue;
}

.mat-button-toggle-checked button {
    color: white;
}

.mat-expansion-indicator:after {
    color: $primaryBlue !important;
    padding: 6px !important;
}

.voortgang-bar .mat-progress-bar-fill::after {
    background: linear-gradient(to right, #336699 0%, #558592 55%, #75a38c 100%) !important;
}

//DocumentsoverView Styling

.document-add-dialog-styling mat-dialog-container {
    border-radius: 2vh;
    padding: 0 !important;
    background-color: #eaeef1;
    overflow: hidden;
    width: 30vw;
}

.custom-snackbar-styling {
    border-radius: 1vh !important;
    margin: 2.5vh !important;
    min-width: 0 !important;
    padding: 2vh 1.5vw !important;
    min-height: 0 !important;
    background-color: #4caf50;
    color: white;
}

.error-snackbar {
    background-color: red;
    text-align: center;
    color: white;
}

.mat-tree-node {
    cursor: pointer;
}

.tree-node-selected {
    background-color: #89acda;
    border-radius: 1vh;
}

.tree-node-selected p {
    color: white !important;
}

.tree-node-selected .mat-icon-button .mat-icon {
    color: white !important;
}

.mat-dialog-container {
    padding: 0 !important;
}

.wb-default-label {
    border: 1px solid black;
    background-color: lightgray;
    color: black;
}

.wb-documents-filter-label {
    padding: 10px;
    border-radius: 8px !important;
    height: auto;
    width: auto;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
}

.wki-doc-overview .mat-dialog-container {
    background-color: #eaeaea !important;
}

.color-red {
    color: $red;
}

.generic-dialog {
    mat-dialog-container {
        border-radius: 1.5vh !important;
        padding: 0 !important;
        background-color: #eaeef1 !important;
        overflow: hidden !important;
    }
}

.pop-up-background-dim {
    background: #000000b5;
}

.capitalize::first-letter {
    text-transform:capitalize;
}
